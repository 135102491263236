//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillAdd, documentBillEdit } from '@/api/modular/fileStream/documentManage'
import upload from '@/views/fileStream/documentManage/upload.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import minxin from '../../components/minxin'
export default {
    components: {
        OpenData,
        upload,
    },
    mixins: [minxin],
    data() {
        return {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 19 },
            },
            form: this.$form.createForm(this),
            fileIds: [],
            content: '',
            ngdw_list: [],
            hgdw_list: [],
            wbhgdw_list: [], // 外部會稿單位
            checkIds: [],
            mainIds: [],
            sendIds: [],
        }
    },
    methods: {
        clearMessage() {
            this.clear_form()
            this.empty()
            // this.$refs.editor.msg = ''
        },
        showSelectDialog(key) {
            this.$emit('showSelectDialog', key)
            // this.$refs.selectPerson.open({ key })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        setLabelPerson({ result, key }) {
            result.forEach((element) => {
                if (!this[key].some((j) => this.objectEquail(j, element))) {
                    this[key].push(element)
                }
            })
            this.reassignData()
        },
        // 重新賦值表單數據
        reassignData() {
            this.form.setFieldsValue({
                mainIds: this.mainIds,
                ngdw_list: this.ngdw_list,
            })
        },
        get_info(query) {
            this.form_id = query.id
            const id = this.form_id
            if (id) {
                this.apiBillDetail(id).then((res) => {
                    const {
                        documentNumber,
                        outDraftDesc,
                        name,
                        urgencyDegree,
                        secrecyDegree,
                        draftId,
                        fileType,
                        documentFileList,
                        content,
                        meetId,
                        outDraftId,
                    } = res.data
                    const form = {
                        biaoti: name,
                        jjcd: urgencyDegree ? String(urgencyDegree) : '',
                        miji: secrecyDegree ? String(secrecyDegree) : '',
                        userId: draftId,
                        xzfn: fileType ? String(fileType) : '',
                        fileIds: documentFileList,
                        content,
                        hgdw_list: meetId,
                        wbhgdw_list: outDraftId,
                        checkIds: res.data.checkIds,
                        mainIds: res.data.mainIds,
                        sendIds: res.data.sendIds,
                        documentNumber,
                        outDraftDesc,
                    }
                    //
                    this.form.setFieldsValue(this.delEmptKey(form))
                    //
                    this.ngdw_list = this.formatDraftId(draftId)
                    this.hgdw_list = this.formatDraftId(meetId)
                    this.wbhgdw_list = this.formatDraftId(outDraftId)
                    this.checkIds = this.formatDraftId(res.data.checkIds)
                    this.mainIds = this.formatDraftId(res.data.mainIds)
                    this.sendIds = this.formatDraftId(res.data.sendIds)
                    //
                    // this.$refs.editor.msg = content
                    this.fileIds = documentFileList
                })
            }
        },

        clear_form() {
            this.form.resetFields()
            this.ngdw_list = []
            this.hgdw_list = []
            this.wbhgdw_list = []
            this.checkIds = []
            this.mainIds = []
            this.sendIds = []
        },
        empty() {
            this.$refs.upload.empty()
        },
        delEmptKey(obj) {
            const objCopy = { ...obj }
            const keys = Object.keys(objCopy)
            for (const index of keys) {
                if (!objCopy[index]) delete objCopy[index]
            }
            return objCopy
        },
        handleSubmit(e, status) {
            // eslint-disable-next-line camelcase
            const { filter, content, fileIds, ngdw_list, hgdw_list, wbhgdw_list, checkIds, mainIds, sendIds } = this
            let func = documentBillAdd
            const id = this.form_id
            if (id) {
                func = documentBillEdit
            }
            console.log('form+++++++++')
            this.form.validateFields((err, values) => {
                if (!err) {
                    const obj = {
                        name: values.biaoti,
                        urgencyDegree: Number(values.jjcd) || '',
                        secrecyDegree: Number(values.miji) || '',
                        draftId: filter(ngdw_list),
                        fileType: Number(values.xzfn) || '',
                        fileIds,
                        content,
                        documentNumber: values.documentNumber,
                        outDraftDesc: values.outDraftDesc,
                        meetId: filter(hgdw_list),
                        outDraftId: filter(wbhgdw_list),
                        documentType: 2,
                        checkIds: filter(checkIds),
                        mainIds: filter(mainIds),
                        sendIds: filter(sendIds),
                        status,
                    }
                    if (id) {
                        obj.id = id
                    }
                    func(this.delEmptKey(obj)).then((res) => {
                        if (!res.success) {
                            return
                        }
                        this.$message.success(res.message)
                        this.clearMessage()
                        this.$emit('changeTab', status === 1 ? 3 : 1)
                    })
                }
            })
        },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
        choose_Persons(key) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(key, null, this[key])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    const finalyValue = [...result, ...resPart]
                    this.$set(this, key, finalyValue)
                    this.form.setFieldsValue({ [key]: finalyValue.map((j) => j.userId || j.id).join() })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        filter(ids) {
            return JSON.stringify(
                ids.map(({ corpId, userId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type,
                }))
            )
        },
    },
}
